<template>
  <div class="font-Roboto">
    <Parent />
    <v-card class="mt-3">
      <div class="pt-3 d-flex pb-3">
        <v-card color="#525252" flat height="29px" width="120px" class="ml-4">
          <div class="d-flex justify-center align-center title-card">
            <v-icon class="">$whiteDailyReport</v-icon>
            <div class="font-18px white--text ml-2">
              日報一覧
            </div>
          </div>
        </v-card>
        <div class="dropdown-main-div mx-4 ml-3">
          <v-select
            dense
            solo
            flat
            :items="months"
            hide-details
            append-icon="$purpleDownArrow"
            background-color="#D8D5FC"
            v-model="selectedMonth"
            @change="getDataFromApi"
            height="29"
          >
            <template v-slot:selection="{ item }">
              <span class="primary--text font-14px">
                {{ item }}
              </span>
            </template>
          </v-select>
        </div>
      </div>
      <div class="mt-3 divider"></div>
      <div class="d-flex ml-14 mt-3 font-Roboto">
        <div class="summary-one">
          <div class="d-flex align-center">
            <div class="font-12px text-52 text-center pl-3">総売上</div>
            <div class="ml-5 font-18px fw-500 text-52">
              {{ (salesReport?.sales || 0) | formatMoney }}
            </div>
          </div>
          <div class="d-flex align-center mt-2">
            <div class="font-12px text-52 text-center text-light-blue">
              目標売上
            </div>
            <div class="ml-5 font-14px text-52 text-light-blue">
              {{ (salesReport?.goal || 0) | formatMoney }}
            </div>
            <v-chip
              class="ml-3 d-flex px-2 font-12px text-light-blue chip-class"
              color="rgba(0, 148, 255, 0.15)"
            >
              {{ salesReport?.goal_percentage || 0 }}%
            </v-chip>
          </div>
        </div>

        <div class="summary-two">
          <div class="d-flex align-center">
            <div class="font-12px text-52">( 現金売上 )</div>
            <div class="ml-5 font-18px fw-500 text-52">
              {{ (salesReport?.cash_sales || 0) | formatMoney }}
            </div>
          </div>
          <div class="d-flex align-center mt-2 ml-4">
            <div class="font-12px text-52 text-center">
              人件費
            </div>
            <div class="ml-4 font-14px text-52">
              {{ (costsReport?.labor_cost || 0) | formatMoney }}
            </div>
            <v-chip
              class="ml-5 d-flex px-2 font-12px text-58 chip-class"
              color="rgba(88, 88, 88, 0.15)"
            >
              {{ costsReport?.labor_cost_percentage || 0 }}%
            </v-chip>
          </div>
          <div class="d-flex align-center mt-2 ml-0">
            <div class="font-12px text-52 text-center">
              PF手数料
            </div>
            <div class="ml-4 font-14px text-52">
              {{ (costsReport?.platform_cost || 0) | formatMoney }}
            </div>
            <v-chip
              class="ml-5 d-flex px-2 font-12px text-58 chip-class"
              color="rgba(88, 88, 88, 0.15)"
            >
              {{ costsReport?.platform_cost_percentage || 0 }}%
            </v-chip>
          </div>
        </div>

        <div class="summary-two">
          <div class="d-flex align-center">
            <div class="font-12px text-52">総コスト</div>
            <div class="ml-5 font-18px fw-500 text-52">
              {{ (costsReport?.costs || 0) | formatMoney }}
            </div>
          </div>
          <div class="d-flex align-center mt-2 ml-2">
            <div class="font-12px text-52 text-center">
              食材費
            </div>
            <div class="ml-4 font-14px text-52">
              {{ (costsReport?.foodstuff_cost || 0) | formatMoney }}
            </div>
            <v-chip
              class="ml-5 d-flex font-12px text-58 px-2 chip-class"
              color="rgba(88, 88, 88, 0.15)"
            >
              {{ costsReport?.food_cost_percentage || 0 }}%
            </v-chip>
          </div>
          <div class="d-flex align-center mt-2 ml-2">
            <div class="font-12px text-52 text-center">
              その他
            </div>
            <div class="ml-4 font-14px text-52">
              {{ (costsReport?.others_cost || 0) | formatMoney }}
            </div>
            <v-chip
              class="ml-5 d-flex font-12px text-58 px-2 chip-class"
              color="rgba(88, 88, 88, 0.15)"
            >
              {{ costsReport?.others_cost_percentage || 0 }}%
            </v-chip>
          </div>
        </div>
        <div class="ml-10">
          <div class="d-flex align-center">
            <div class="font-12px text-52">損益</div>
            <div class="ml-5 font-18px fw-500 text-52">
              {{ (salesReport?.sales - costsReport?.costs || 0) | formatMoney }}
            </div>
          </div>
          <div class="d-flex align-center mt-2">
            <div class="font-12px text-52 text-center">
              家賃
            </div>
            <div class="ml-4 font-14px text-52">
              {{ costsReport?.rent_cost || 0 | formatMoney }}
            </div>
            <v-chip
              class="ml-5 d-flex font-12px text-58 px-2 chip-class"
              color="rgba(88, 88, 88, 0.15)"
            >
              {{ costsReport?.rent_cost_percentage || 0 }}%
            </v-chip>
          </div>
        </div>
      </div>
      <Table
        class="mt-5"
        disable-pagination
        :headers="tableHeaders"
        :items="dailyReports"
        :total-records="dailyReports.length"
        :footer="false"
      >
        <template v-slot:item="{ item, headers, index }">
          <tr>
            <td class="text-center">
              {{ item.day }}
            </td>
            <td class="text-center">
              <template v-if="!item.weather">--</template>
              <template v-for="(weather, i) in item.weather">
                <v-icon :key="`weather-${index}-${i}`">
                  {{ weather }}
                </v-icon>
                <template v-if="i !== item.weather.length - 1">
                  /
                </template>
              </template>
            </td>
            <template v-if="item.is_holiday">
              <td class="text-center text-red">
                店休日
              </td>
              <td :colspan="headers.length - 4"></td>
            </template>
            <template v-else>
              <td
                class="text-center"
                v-for="(header, i) in tableHeaders.filter(
                  (v, j) => j > 1 && j !== tableHeaders.length - 1
                )"
                :key="`daily-report-item-${index}-${i}`"
              >
                {{ showValue(header, item) }}
              </td>
            </template>
            <td>
              <v-icon
                @click="
                  $router.push({
                    name: 'DailyReportDetail',
                    params: { date: item.report_date }
                  })
                "
                size="15"
              >
                $arrowRight
              </v-icon>
            </td>
          </tr>
        </template>
      </Table>
    </v-card>
  </div>
</template>

<script>
import Parent from "@/views/admin/Store/Index";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
import Table from "@/components/admin/partials/Table/Table";

export default {
  name: "Index",
  components: { Table, Parent },
  filters: {
    formatMoney(value) {
      if (!Number.isInteger(value)) return value;
      if (value === -1) return "￥--";
      const money = new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
      return money.format(value);
    }
  },
  computed: {
    ...mapGetters([
      "getShopDailyReports",
      "getShopMonthlyCumulatedSalesReport",
      "getShopMonthlyCumulatedCostsReport"
    ]),
    salesReport() {
      return this.getShopMonthlyCumulatedSalesReport;
    },
    costsReport() {
      return this.getShopMonthlyCumulatedCostsReport;
    },
    dailyReports() {
      return this.getShopDailyReports.map(report => {
        return {
          ...report,
          day: dayjs(report.report_date).format("MM/DD"),
          weather:
            report.weather?.map(weather => this.weathers[weather - 1]) || null
        };
      });
    },
    months() {
      let now = dayjs();
      let months = [];
      let begin = now.startOf("year").subtract(1, "year");
      let end = now.endOf("year");

      while (begin.isSameOrBefore(end)) {
        months.push(begin.format("YYYY/MM"));

        begin = begin.add(1, "month");
      }
      return months;
    }
  },
  data() {
    return {
      selectedMonth: "",
      weathers: ["$sunnyWeather", "$cloudyWeather", "$rainyWeather"],
      tableHeaders: [
        { text: "月", sortable: false, align: "center", value: "day" },
        {
          text: "天気",
          sortable: false,
          width: 150,
          align: "center",
          value: "weather"
        },
        { text: "売上実績", sortable: false, align: "center", value: "sales" },
        {
          text: "ランチ売上",
          sortable: false,
          align: "center",
          value: "lunch_sales"
        },
        {
          text: "ディナー売上",
          sortable: false,
          align: "center",
          value: "dinner_sales"
        },
        {
          text: "現金売上",
          sortable: false,
          align: "center",
          value: "cash_sales"
        },
        { text: "コスト", sortable: false, align: "center", value: "costs" },
        {
          text: "損益",
          sortable: false,
          align: "center",
          value: "profit_or_loss"
        },
        {
          text: "客数",
          sortable: false,
          align: "center",
          value: "number_of_total_customer",
          isMoney: false
        },
        {
          text: "客単価",
          sortable: false,
          align: "center",
          value: "average_customer_spend"
        },
        { text: "", sortable: false, align: "center" }
      ]
    };
  },
  mounted() {
    this.selectedMonth = dayjs().format("YYYY/MM");
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      let date = dayjs(this.selectedMonth, "YYYY/MM").date(1);
      let month = date.format("YYYY-MM-DD");
      await this.$store.dispatch("SHOP_DAILY_REPORT_ALL", {
        shop_id: this.$route.params.shop_id,
        params: {
          month: month
        }
      });
    },
    showValue(header, item) {
      let val = item[header.value];
      if (!val) {
        val = header.value !== "number_of_total_customer" ? -1 : "--";
      }
      return this.$options.filters.formatMoney(val);
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
