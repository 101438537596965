var render = function () {
  var _vm$salesReport, _vm$salesReport2, _vm$salesReport3, _vm$salesReport4, _vm$costsReport, _vm$costsReport2, _vm$costsReport3, _vm$costsReport4, _vm$costsReport5, _vm$costsReport6, _vm$costsReport7, _vm$costsReport8, _vm$costsReport9, _vm$salesReport5, _vm$costsReport10, _vm$costsReport11, _vm$costsReport12;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-Roboto"
  }, [_c('Parent'), _c('v-card', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "pt-3 d-flex pb-3"
  }, [_c('v-card', {
    staticClass: "ml-4",
    attrs: {
      "color": "#525252",
      "flat": "",
      "height": "29px",
      "width": "120px"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center title-card"
  }, [_c('v-icon', {}, [_vm._v("$whiteDailyReport")]), _c('div', {
    staticClass: "font-18px white--text ml-2"
  }, [_vm._v(" 日報一覧 ")])], 1)]), _c('div', {
    staticClass: "dropdown-main-div mx-4 ml-3"
  }, [_c('v-select', {
    attrs: {
      "dense": "",
      "solo": "",
      "flat": "",
      "items": _vm.months,
      "hide-details": "",
      "append-icon": "$purpleDownArrow",
      "background-color": "#D8D5FC",
      "height": "29"
    },
    on: {
      "change": _vm.getDataFromApi
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "primary--text font-14px"
        }, [_vm._v(" " + _vm._s(item) + " ")])];
      }
    }]),
    model: {
      value: _vm.selectedMonth,
      callback: function callback($$v) {
        _vm.selectedMonth = $$v;
      },
      expression: "selectedMonth"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-3 divider"
  }), _c('div', {
    staticClass: "d-flex ml-14 mt-3 font-Roboto"
  }, [_c('div', {
    staticClass: "summary-one"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center pl-3"
  }, [_vm._v("総売上")]), _c('div', {
    staticClass: "ml-5 font-18px fw-500 text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$salesReport = _vm.salesReport) === null || _vm$salesReport === void 0 ? void 0 : _vm$salesReport.sales) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex align-center mt-2"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center text-light-blue"
  }, [_vm._v(" 目標売上 ")]), _c('div', {
    staticClass: "ml-5 font-14px text-52 text-light-blue"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$salesReport2 = _vm.salesReport) === null || _vm$salesReport2 === void 0 ? void 0 : _vm$salesReport2.goal) || 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-3 d-flex px-2 font-12px text-light-blue chip-class",
    attrs: {
      "color": "rgba(0, 148, 255, 0.15)"
    }
  }, [_vm._v(" " + _vm._s(((_vm$salesReport3 = _vm.salesReport) === null || _vm$salesReport3 === void 0 ? void 0 : _vm$salesReport3.goal_percentage) || 0) + "% ")])], 1)]), _c('div', {
    staticClass: "summary-two"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "font-12px text-52"
  }, [_vm._v("( 現金売上 )")]), _c('div', {
    staticClass: "ml-5 font-18px fw-500 text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$salesReport4 = _vm.salesReport) === null || _vm$salesReport4 === void 0 ? void 0 : _vm$salesReport4.cash_sales) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex align-center mt-2 ml-4"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center"
  }, [_vm._v(" 人件費 ")]), _c('div', {
    staticClass: "ml-4 font-14px text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport = _vm.costsReport) === null || _vm$costsReport === void 0 ? void 0 : _vm$costsReport.labor_cost) || 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-5 d-flex px-2 font-12px text-58 chip-class",
    attrs: {
      "color": "rgba(88, 88, 88, 0.15)"
    }
  }, [_vm._v(" " + _vm._s(((_vm$costsReport2 = _vm.costsReport) === null || _vm$costsReport2 === void 0 ? void 0 : _vm$costsReport2.labor_cost_percentage) || 0) + "% ")])], 1), _c('div', {
    staticClass: "d-flex align-center mt-2 ml-0"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center"
  }, [_vm._v(" PF手数料 ")]), _c('div', {
    staticClass: "ml-4 font-14px text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport3 = _vm.costsReport) === null || _vm$costsReport3 === void 0 ? void 0 : _vm$costsReport3.platform_cost) || 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-5 d-flex px-2 font-12px text-58 chip-class",
    attrs: {
      "color": "rgba(88, 88, 88, 0.15)"
    }
  }, [_vm._v(" " + _vm._s(((_vm$costsReport4 = _vm.costsReport) === null || _vm$costsReport4 === void 0 ? void 0 : _vm$costsReport4.platform_cost_percentage) || 0) + "% ")])], 1)]), _c('div', {
    staticClass: "summary-two"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "font-12px text-52"
  }, [_vm._v("総コスト")]), _c('div', {
    staticClass: "ml-5 font-18px fw-500 text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport5 = _vm.costsReport) === null || _vm$costsReport5 === void 0 ? void 0 : _vm$costsReport5.costs) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex align-center mt-2 ml-2"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center"
  }, [_vm._v(" 食材費 ")]), _c('div', {
    staticClass: "ml-4 font-14px text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport6 = _vm.costsReport) === null || _vm$costsReport6 === void 0 ? void 0 : _vm$costsReport6.foodstuff_cost) || 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-5 d-flex font-12px text-58 px-2 chip-class",
    attrs: {
      "color": "rgba(88, 88, 88, 0.15)"
    }
  }, [_vm._v(" " + _vm._s(((_vm$costsReport7 = _vm.costsReport) === null || _vm$costsReport7 === void 0 ? void 0 : _vm$costsReport7.food_cost_percentage) || 0) + "% ")])], 1), _c('div', {
    staticClass: "d-flex align-center mt-2 ml-2"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center"
  }, [_vm._v(" その他 ")]), _c('div', {
    staticClass: "ml-4 font-14px text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport8 = _vm.costsReport) === null || _vm$costsReport8 === void 0 ? void 0 : _vm$costsReport8.others_cost) || 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-5 d-flex font-12px text-58 px-2 chip-class",
    attrs: {
      "color": "rgba(88, 88, 88, 0.15)"
    }
  }, [_vm._v(" " + _vm._s(((_vm$costsReport9 = _vm.costsReport) === null || _vm$costsReport9 === void 0 ? void 0 : _vm$costsReport9.others_cost_percentage) || 0) + "% ")])], 1)]), _c('div', {
    staticClass: "ml-10"
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "font-12px text-52"
  }, [_vm._v("損益")]), _c('div', {
    staticClass: "ml-5 font-18px fw-500 text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$salesReport5 = _vm.salesReport) === null || _vm$salesReport5 === void 0 ? void 0 : _vm$salesReport5.sales) - ((_vm$costsReport10 = _vm.costsReport) === null || _vm$costsReport10 === void 0 ? void 0 : _vm$costsReport10.costs) || 0)) + " ")])]), _c('div', {
    staticClass: "d-flex align-center mt-2"
  }, [_c('div', {
    staticClass: "font-12px text-52 text-center"
  }, [_vm._v(" 家賃 ")]), _c('div', {
    staticClass: "ml-4 font-14px text-52"
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(((_vm$costsReport11 = _vm.costsReport) === null || _vm$costsReport11 === void 0 ? void 0 : _vm$costsReport11.rent_cost) || 0)) + " ")]), _c('v-chip', {
    staticClass: "ml-5 d-flex font-12px text-58 px-2 chip-class",
    attrs: {
      "color": "rgba(88, 88, 88, 0.15)"
    }
  }, [_vm._v(" " + _vm._s(((_vm$costsReport12 = _vm.costsReport) === null || _vm$costsReport12 === void 0 ? void 0 : _vm$costsReport12.rent_cost_percentage) || 0) + "% ")])], 1)])]), _c('Table', {
    staticClass: "mt-5",
    attrs: {
      "disable-pagination": "",
      "headers": _vm.tableHeaders,
      "items": _vm.dailyReports,
      "total-records": _vm.dailyReports.length,
      "footer": false
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item,
            headers = _ref2.headers,
            index = _ref2.index;
        return [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(item.day) + " ")]), _c('td', {
          staticClass: "text-center"
        }, [!item.weather ? [_vm._v("--")] : _vm._e(), _vm._l(item.weather, function (weather, i) {
          return [_c('v-icon', {
            key: "weather-".concat(index, "-").concat(i)
          }, [_vm._v(" " + _vm._s(weather) + " ")]), i !== item.weather.length - 1 ? [_vm._v(" / ")] : _vm._e()];
        })], 2), item.is_holiday ? [_c('td', {
          staticClass: "text-center text-red"
        }, [_vm._v(" 店休日 ")]), _c('td', {
          attrs: {
            "colspan": headers.length - 4
          }
        })] : _vm._l(_vm.tableHeaders.filter(function (v, j) {
          return j > 1 && j !== _vm.tableHeaders.length - 1;
        }), function (header, i) {
          return _c('td', {
            key: "daily-report-item-".concat(index, "-").concat(i),
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(_vm.showValue(header, item)) + " ")]);
        }), _c('td', [_c('v-icon', {
          attrs: {
            "size": "15"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'DailyReportDetail',
                params: {
                  date: item.report_date
                }
              });
            }
          }
        }, [_vm._v(" $arrowRight ")])], 1)], 2)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }